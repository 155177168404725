// src/components/AvatarCreator.js
import React, { useState, useEffect, useMemo } from "react";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import "./AvatarCreator.css";

const gradients = [
  { name: "White-Grey", gradient: "linear-gradient(to bottom, white, grey)" },
  {
    name: "Dark Grey",
    gradient: "linear-gradient(to bottom, #4b4b4b, #bdbdbd)",
  },
  {
    name: "Gentle Pink",
    gradient: "linear-gradient(to bottom, #ff9a9e, #fad0c4)",
  },
  {
    name: "Gentle Purple",
    gradient: "linear-gradient(to bottom, #a18cd1, #fbc2eb)",
  },
  {
    name: "Gentle Peach",
    gradient: "linear-gradient(to bottom, #fad0c4, #ffd1ff)",
  },
  {
    name: "Gentle Blue",
    gradient: "linear-gradient(to bottom, #a1c4fd, #c2e9fb)",
  },
  {
    name: "Gentle Green",
    gradient: "linear-gradient(to bottom, #d4fc79, #96e6a1)",
  },
  {
    name: "Gentle Orange",
    gradient: "linear-gradient(to bottom, #ffecd2, #fcb69f)",
  },
];

const AvatarCreator = ({ onChange }) => {
  const [ducks, setDucks] = useState([]);
  const [hats, setHats] = useState([]);
  const [glasses, setGlasses] = useState([]);
  const [selectedDuck, setSelectedDuck] = useState(null);
  const [selectedHat, setSelectedHat] = useState(null);
  const [selectedGlasses, setSelectedGlasses] = useState(null);
  const [selectedGradient, setSelectedGradient] = useState(gradients[0].name);
  const selectedGradientGradient = useMemo(() => {
    return gradients.find((gradient) => {
      return gradient.name === selectedGradient;
    })?.gradient;
  }, [selectedGradient]);

  useEffect(() => {
    onChange({ selectedDuck, selectedHat, selectedGlasses, selectedGradient });
  }, [onChange, selectedDuck, selectedGlasses, selectedGradient, selectedHat]);

  useEffect(() => {
    const storage = getStorage();

    const fetchSvgs = async (folder, setFunction) => {
      try {
        const folderRef = ref(storage, folder);
        const result = await listAll(folderRef);
        const urls = await Promise.all(
          result.items.map((item) => getDownloadURL(item))
        );
        setFunction(urls);
        if (folder === "avatars/parts/ducks/") {
          setSelectedDuck(urls[0]); // Set the first duck as default
        }
      } catch (error) {
        console.error(`Error fetching SVGs from ${folder}: `, error);
      }
    };

    fetchSvgs("avatars/parts/ducks/", setDucks);
    fetchSvgs("avatars/parts/hats/", setHats);
    fetchSvgs("avatars/parts/glasses/", setGlasses);
  }, []);

  return (
    <div className="avatar-creator">
      <h2 className="text-2xl font-bold mb-2">Create An Avatar</h2>
      <p className="subheader">
        You can create a unique avatar for each cruise.
      </p>
      <div className="avatar-content">
        <div
          className="avatar-preview"
          style={{
            background: selectedGradientGradient,
            width: "250px",
            height: "300px",
            position: "relative",
          }}
        >
          {selectedDuck && (
            <img
              src={selectedDuck}
              alt="Duck"
              style={{ position: "absolute", width: "100%", height: "100%" }}
            />
          )}
          {selectedGlasses && (
            <img
              src={selectedGlasses}
              alt="Glasses"
              style={{ position: "absolute", width: "100%", height: "100%" }}
            />
          )}
          {selectedHat && (
            <img
              src={selectedHat}
              alt="Hat"
              style={{ position: "absolute", width: "100%", height: "100%" }}
            />
          )}
        </div>

        <div className="avatar-controls">
          <div className="control-section">
            <h3>Background</h3>
            <div className="avatar-options">
              {gradients.map((gradient) => (
                <button
                  type="button"
                  key={gradient.name}
                  onClick={() => setSelectedGradient(gradient.name)}
                  className={
                    selectedGradient === gradient.name ? "selected" : ""
                  }
                  style={{
                    background: gradient.gradient,
                    width: "50px",
                    height: "50px",
                  }}
                />
              ))}
            </div>
          </div>

          <div className="control-section">
            <h3>Duck</h3>
            <div className="avatar-options">
              {ducks.map((url) => (
                <button
                  type="button"
                  key={url}
                  onClick={() => setSelectedDuck(url)}
                  className={selectedDuck === url ? "selected" : ""}
                >
                  <img src={url} alt="Duck" width="50" />
                </button>
              ))}
            </div>
          </div>

          <div className="control-section">
            <h3>Hat</h3>
            <div className="avatar-options">
              <button
                type="button"
                onClick={() => setSelectedHat(null)}
                className={!selectedHat ? "selected" : ""}
              >
                None
              </button>
              {hats.map((url) => (
                <button
                  type="button"
                  key={url}
                  onClick={() => setSelectedHat(url)}
                  className={selectedHat === url ? "selected" : ""}
                >
                  <img src={url} alt="Hat" width="50" />
                </button>
              ))}
            </div>
          </div>

          <div className="control-section">
            <h3>Glasses</h3>
            <div className="avatar-options">
              <button
                type="button"
                onClick={() => setSelectedGlasses(null)}
                className={!selectedGlasses ? "selected" : ""}
              >
                None
              </button>
              {glasses.map((url) => (
                <button
                  type="button"
                  key={url}
                  onClick={() => setSelectedGlasses(url)}
                  className={selectedGlasses === url ? "selected" : ""}
                >
                  <img src={url} alt="Glasses" width="50" />
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvatarCreator;
