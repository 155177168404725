// src/firebase.js
import { initializeApp } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";

import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDha_eSgI86aDPBOsauLg7F2pPOjmnxSuE",
  authDomain: "crews-31a4c.firebaseapp.com",
  databaseURL: "https://crews-31a4c-default-rtdb.firebaseio.com",
  projectId: "crews-31a4c",
  storageBucket: "crews-31a4c.appspot.com",
  messagingSenderId: "357002170589",
  appId: "1:357002170589:web:ca307f16b5869ab8798b34",
  measurementId: "G-KKR6YL8ZB2",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Auth
const auth = getAuth(app);

const db = getFirestore(app);

const storage = getStorage(app);

export {
  auth,
  db,
  storage,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
};
