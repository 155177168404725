import React, { useEffect, useState, useRef, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db, auth } from "../firebase";
import {
  doc,
  getDoc,
  collection,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import AvatarCreator from "./AvatarCreator";
import SoloIcon from "./icons/solo.svg";
import CoupleIcon from "./icons/couple.svg";
import FamilyIcon from "./icons/family.svg";
import { FaCheck } from "react-icons/fa";
import Footer from "./Footer";

const ProfileEdit = () => {
  const { uid } = useParams();
  const navigate = useNavigate();
  const [profileName, setProfileName] = useState("");
  const [bio, setBio] = useState("");
  const [travellerType, setTravellerType] = useState("");
  const [openToMeeting, setOpenToMeeting] = useState([]);
  const [interests, setInterests] = useState([]);
  const [availableActivities, setAvailableActivities] = useState([]);
  const [charCount, setCharCount] = useState(0);
  const [avatarUrl, setAvatarUrl] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const profilesRef = collection(db, "userProfiles");
        const querySnapshot = await getDocs(profilesRef);
        const profileDoc = querySnapshot.docs.find(
          (doc) => doc.data().uid === uid
        );
        if (profileDoc) {
          const profileData = profileDoc.data();
          setProfileName(profileData.profileName);
          setBio(profileData.bio);
          setTravellerType(profileData.travellerType);
          setOpenToMeeting(profileData.openToMeeting);
          setInterests(profileData.interests);
          setAvatarUrl(profileData.avatarUrl);
        } else {
          console.error("No such document!");
        }
      } catch (error) {
        console.error("Error fetching profile: ", error);
      }
    };

    fetchProfile();
  }, [uid]);

  useEffect(() => {
    const fetchActivities = async () => {
      const activitiesCollection = collection(db, "activities");
      const activitiesSnapshot = await getDocs(activitiesCollection);
      const activitiesList = activitiesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAvailableActivities(activitiesList);
    };

    fetchActivities();
  }, []);

  const handleToggleInterest = (activity) => {
    if (interests.includes(activity)) {
      setInterests(interests.filter((i) => i !== activity));
    } else {
      setInterests([...interests, activity]);
    }
  };

  const handleToggleOpenToMeeting = (type) => {
    if (openToMeeting.includes(type)) {
      setOpenToMeeting(openToMeeting.filter((t) => t !== type));
    } else {
      setOpenToMeeting([...openToMeeting, type]);
    }
  };

  const handleBioChange = (e) => {
    setBio(e.target.value);
    setCharCount(e.target.value.length);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);

    try {
      const profilesRef = collection(db, "userProfiles");
      const querySnapshot = await getDocs(profilesRef);
      const profileDoc = querySnapshot.docs.find(
        (doc) => doc.data().uid === uid
      );
      const profileRef = doc(db, "userProfiles", profileDoc.id);

      await updateDoc(profileRef, {
        profileName,
        bio,
        travellerType,
        openToMeeting,
        interests,
      });

      navigate("/dashboard");
    } catch (error) {
      console.error("Error saving profile: ", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const getButtonColor = (index) => {
    const colors = [
      "bg-red-500 text-white",
      "bg-green-500 text-white",
      "bg-blue-500 text-white",
      "bg-yellow-500 text-black",
      "bg-purple-500 text-white",
      "bg-pink-500 text-white",
      "bg-teal-500 text-white",
    ];
    return colors[index % colors.length];
  };

  return (
    <div className="container mx-auto p-4 bg-white rounded-lg shadow-md">
      <h2 className="text-3xl font-bold mb-4">Edit Your Profile</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <h2 className="text-2xl font-bold mb-2">Profile Name and Bio</h2>
          <input
            type="text"
            value={profileName}
            onChange={(e) => setProfileName(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div>
          <label className="block text-gray-700">
            Tell us about you{" "}
            <span className="text-sm text-gray-500">
              (remember to keep yourself safe)
            </span>
          </label>
          <input
            type="text"
            value={bio}
            onChange={handleBioChange}
            maxLength="100"
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
          <div
            className={`text-sm ${
              charCount === 100 ? "text-red-500" : "text-gray-500"
            } text-right`}
          >
            {charCount}/100
          </div>
        </div>
        <div>
          <h2 className="text-2xl font-bold mb-2">
            Type of traveler: (select one)
          </h2>
          <div className="flex justify-center space-x-4">
            {[
              { type: "Solo", icon: SoloIcon },
              { type: "Couple", icon: CoupleIcon },
              { type: "Family", icon: FamilyIcon },
            ].map(({ type, icon: Icon }) => (
              <button
                key={type}
                type="button"
                className={`py-4 px-8 rounded-lg text-xl flex flex-col items-center ${
                  travellerType === type
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 text-gray-700"
                }`}
                onClick={() => setTravellerType(type)}
              >
                <img
                  src={Icon}
                  alt={`${type} icon`}
                  className="h-12 w-12 mb-2"
                />
                {type}
              </button>
            ))}
          </div>
        </div>
        <div>
          <h2 className="text-2xl font-bold mb-2">
            Open to meeting: (select at least one)
          </h2>
          <div className="flex justify-center space-x-4">
            {[
              { type: "Solo", icon: SoloIcon },
              { type: "Couple", icon: CoupleIcon },
              { type: "Family", icon: FamilyIcon },
            ].map(({ type, icon: Icon }) => (
              <button
                key={type}
                type="button"
                className={`py-4 px-8 rounded-lg text-xl flex flex-col items-center ${
                  openToMeeting.includes(type)
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 text-gray-700"
                }`}
                onClick={() => handleToggleOpenToMeeting(type)}
              >
                <img
                  src={Icon}
                  alt={`${type} icon`}
                  className="h-12 w-12 mb-2"
                />
                {type}
              </button>
            ))}
          </div>
        </div>
        <div>
          <h2 className="text-2xl font-bold mb-2">Interests</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
            {availableActivities.map((activity, index) => (
              <button
                key={activity.id}
                type="button"
                className={`py-1 px-3 rounded-full ${getButtonColor(index)} ${
                  interests.includes(activity.name)
                    ? "opacity-100"
                    : "opacity-70"
                }`}
                onClick={() => handleToggleInterest(activity.name)}
              >
                <div className="flex items-center">
                  {activity.name}
                  {interests.includes(activity.name) && (
                    <FaCheck className="ml-2 text-white" />
                  )}
                </div>
              </button>
            ))}
          </div>
        </div>
        <div>
          <h2 className="text-2xl font-bold mb-2">Your Avatar</h2>
          <div className="w-full h-64 mb-4 border border-gray-300 rounded-lg flex items-center justify-center">
            <img src={avatarUrl} alt="Avatar" className="max-h-full" />
          </div>
        </div>
        <div className="flex space-x-4">
          <button
            type="submit"
            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-200"
            disabled={isSubmitting}
          >
            Save Changes
          </button>
          <button
            type="button"
            onClick={() => navigate("/dashboard")}
            className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-700 transition duration-200"
          >
            Cancel
          </button>
        </div>
      </form>
      <Footer />
    </div>
  );
};

export default ProfileEdit;
