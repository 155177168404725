// src/components/AddAchievement.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { db, storage, auth } from "../firebase";
import { collection, addDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

const AddAchievement = () => {
  const navigate = useNavigate();
  const [achievementName, setAchievementName] = useState("");
  const [pointValue, setPointValue] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [uploading, setUploading] = useState(false);

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploading(true);

    try {
      const user = auth.currentUser;
      if (!user) {
        console.error("User is not authenticated");
        setUploading(false);
        return;
      }

      let imageUrl = "";
      if (image) {
        const storageRef = ref(storage, `achievements/${image.name}`);
        await uploadBytes(storageRef, image);
        imageUrl = await getDownloadURL(storageRef);
      }

      const achievementData = {
        name: achievementName,
        points: parseInt(pointValue, 10),
        description: description,
        imageUrl,
        createdBy: user.uid,
        createdAt: new Date().toISOString(),
      };

      await addDoc(collection(db, "achievements"), achievementData);
      navigate("/achievements");
    } catch (error) {
      console.error("Error adding achievement: ", error);
    } finally {
      setUploading(false);
    }
  };

  const handleCancel = () => {
    navigate("/achievements");
  };

  return (
    <div className="container mx-auto p-4 bg-white rounded-lg shadow-md">
      <h2 className="text-3xl font-bold mb-4">Add Achievement</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-gray-700">Achievement Name</label>
          <input
            type="text"
            value={achievementName}
            onChange={(e) => setAchievementName(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div>
          <label className="block text-gray-700">Point Value</label>
          <input
            type="number"
            value={pointValue}
            onChange={(e) => setPointValue(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div>
          <label className="block text-gray-700">Description</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div>
          <label className="block text-gray-700">Upload Image</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div className="flex space-x-4">
          <button
            type="submit"
            className={`bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-200 ${
              uploading && "opacity-50 cursor-not-allowed"
            }`}
            disabled={uploading}
          >
            {uploading ? "Uploading..." : "Submit"}
          </button>
          <button
            type="button"
            onClick={handleCancel}
            className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-700 transition duration-200"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddAchievement;
