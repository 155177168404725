import React, { useEffect, useState } from "react";
import { db, auth } from "../firebase";
import { collection, addDoc, getDocs } from "firebase/firestore";

const Activities = () => {
  const [activity, setActivity] = useState("");
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const activitiesCollection = collection(db, "activities");
        const activitiesSnapshot = await getDocs(activitiesCollection);
        const activitiesList = activitiesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setActivities(activitiesList);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching activities: ", error);
      }
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchActivities();
      } else {
        console.error("User is not authenticated");
      }
    });

    return () => unsubscribe();
  }, []);

  const handleAddActivity = async () => {
    if (activity.trim() === "") return;

    const newActivity = {
      name: activity,
    };

    try {
      await addDoc(collection(db, "activities"), newActivity);
      setActivities([...activities, newActivity]);
      setActivity("");
    } catch (error) {
      console.error("Error adding activity: ", error);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">Manage Activities</h1>
      <div className="mb-4">
        <input
          type="text"
          value={activity}
          onChange={(e) => setActivity(e.target.value)}
          placeholder="Enter an activity"
          className="border border-gray-300 p-2 rounded mr-2"
        />
        <button
          onClick={handleAddActivity}
          className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-200"
        >
          Add Activity
        </button>
      </div>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b">Activity</th>
            </tr>
          </thead>
          <tbody>
            {activities.map((activity, index) => (
              <tr key={index}>
                <td className="py-2 px-4 border-b">{activity.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Activities;
