// src/components/FAQ.js
import React from "react";
import Footer from "./Footer";

const FAQ = () => {
  return (
    <div className="container mx-auto p-4 bg-white rounded-lg shadow-md">
      <h2 className="text-3xl font-bold mb-4">Frequently Asked Questions</h2>

      <div className="mb-4">
        <h3 className="text-2xl font-semibold mb-2">What is Crews?</h3>
        <p>
          Crews is a social networking platform designed specifically for cruise
          lovers. Whether you're a solo traveler, a couple, or a family, Crews
          helps you connect with other passengers on your voyage, making it
          easier to make friends and enjoy your time at sea.
        </p>
      </div>

      <div className="mb-4">
        <h3 className="text-2xl font-semibold mb-2">Who makes Crews?</h3>
        <p>
          Crews was thought up by Cristina and developed by her husband,
          Douglas. They live in Northern Virginia with their dog, Rory.
          Together, they combine their passion for cruising and technology to
          create a platform that enhances the cruise experience for everyone.
        </p>
      </div>

      <div className="mb-4">
        <h3 className="text-2xl font-semibold mb-2">
          What data do you store about me?
        </h3>
        <p>
          As little as possible, we promise! We're very aware how easily
          personal information can get into the wrong hands. We take your email
          address, for getting you logged in and being able to reset your
          password, but we never ask you to give out your real name or location.
          When you start searching for your potential crews, we keep track of
          which ship you select, what kind of traveler you are on board (solo,
          couple, or family), what kind of crews you're looking to join, and
          what activities you're into. We temporarily store messages between you
          and other members. We do use Google Analytics, and you can find out
          what information they're gathering on their help pages at{" "}
          <a
            href="https://support.google.com/analytics/answer/6004245"
            className="text-blue-500 underline"
          >
            Google Analytics Help
          </a>
          .
        </p>
      </div>

      <div className="mb-4">
        <h3 className="text-2xl font-semibold mb-2">
          What do I do if I have a problem?
        </h3>
        <p>
          Feel free to send us a message, we're really friendly and will try to
          help. Our support team is dedicated to resolving any issues you may
          encounter and ensuring that your experience with Crews is as smooth
          and enjoyable as possible.
        </p>
      </div>

      <Footer />
    </div>
  );
};

export default FAQ;
