// src/App.js
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import ReactGA from "react-ga4";

import Navbar from "./components/Navbar";
import Register from "./components/Register";
import Login from "./components/Login";
import ForgotPassword from "./components/ForgotPassword";
import Home from "./components/Home";
import Dashboard from "./components/Dashboard";
import ChangePassword from "./components/ChangePassword";
import Activities from "./components/Activities";
import ProfileForm from "./components/ProfileForm";
import AddAchievement from "./components/AddAchievement";
import UserCruises from "./components/UserCruises";
import CruiseDetails from "./components/CruiseDetails";
import Locker from "./components/Locker";
import AssignAchievement from "./components/AssignAchievement";
import FAQ from "./components/FAQ";
import Contact from "./components/Contact";
import Chats from "./components/Chats";
import Beacon from "./components/Beacon";
import ProfileEdit from "./components/ProfileEdit";
import CruiseUploader from "./components/CruiseUploader";

ReactGA.initialize("G-KKR6YL8ZB2");

const TrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  return null;
};

function App() {
  useEffect(() => {
    document.title = "Crews - Find Your Cruise Companions";
  }, []);

  return (
    <div className="bg-full-screen">
      <Router>
        <Navbar />
        <TrackPageView />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/activities" element={<Activities />} />
          <Route path="/profile/:cruiseId" element={<ProfileForm />} />
          <Route path="/add-achievement" element={<AddAchievement />} />
          <Route path="/my-cruises" element={<UserCruises />} />
          <Route path="/cruise-details/:cruiseId" element={<CruiseDetails />} />
          <Route path="/locker" element={<Locker />} />
          <Route path="/assign-achievement" element={<AssignAchievement />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/chats" element={<Chats />} />
          <Route path="/my-chats" element={<Chats />} />
          <Route path="/beacon/:cruiseId" element={<Beacon />} />
          <Route path="/profile-edit/:uid" element={<ProfileEdit />} />
          <Route path="/cruise-uploader" element={<CruiseUploader />} />
          <Route
            path="/contact/:cruiseId/:contactUserId"
            element={<Contact />}
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
