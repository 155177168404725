// src/components/Register.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { db } from "../firebase";
import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  serverTimestamp,
  doc,
  setDoc,
} from "firebase/firestore";

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const auth = getAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Fetch the welcome achievement from the achievements collection
      const achievementsRef = collection(db, "achievements");
      const welcomeAchievementQuery = query(
        achievementsRef,
        where("name", "==", "Welcome")
      );
      const querySnapshot = await getDocs(welcomeAchievementQuery);
      const welcomeAchievement = querySnapshot.docs[0]?.data();

      const newUser = {
        email: user.email,
        uid: user.uid,
        points: welcomeAchievement ? welcomeAchievement.points : 0,
        achievements: welcomeAchievement ? [querySnapshot.docs[0].id] : [],
      };

      // Using setDoc to ensure the document ID matches the user UID
      await setDoc(doc(db, "users", user.uid), newUser);

      // Log the achievement in achievementHistory
      if (welcomeAchievement) {
        await addDoc(collection(db, "achievementHistory"), {
          achievementId: querySnapshot.docs[0].id,
          timestamp: serverTimestamp(),
          uid: user.uid,
        });
      }

      navigate("/dashboard");
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="bg-white bg-opacity-75 p-8 rounded-lg shadow-lg max-w-md mx-auto">
      <h2 className="text-2xl font-bold mb-4">Register</h2>
      {error && <div className="text-red-500 mb-4">{error}</div>}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-gray-700">Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            placeholder="Email"
            required
          />
        </div>
        <div>
          <label className="block text-gray-700">Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            placeholder="Password"
            required
          />
        </div>
        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-700 transition duration-200"
        >
          Register
        </button>
      </form>
    </div>
  );
};

export default Register;
