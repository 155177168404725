// src/components/Contact.js
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { db, auth } from "../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  serverTimestamp,
  onSnapshot,
  updateDoc,
  doc,
  increment,
  arrayUnion,
  writeBatch,
} from "firebase/firestore";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { FaUserCircle } from "react-icons/fa";

const Contact = () => {
  const { cruiseId, contactUserId } = useParams();
  const [shipName, setShipName] = useState("");
  const [profileName, setProfileName] = useState("");
  const [recipientAvatarUrl, setRecipientAvatarUrl] = useState(null);
  const [userAvatarUrl, setUserAvatarUrl] = useState(null);
  const [sharedInterests, setSharedInterests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [suggestMeeting, setSuggestMeeting] = useState(false);
  const [meetingDetails, setMeetingDetails] = useState({
    location: "",
    date: "",
    time: "",
  });

  useEffect(() => {
    const fetchDetails = async () => {
      if (!cruiseId || !contactUserId) {
        console.error("Missing cruiseId or contactUserId");
        setLoading(false);
        return;
      }

      try {
        const cruisesRef = collection(db, "cruises");
        const cruiseQuery = query(
          cruisesRef,
          where("CruiseID", "==", cruiseId)
        );
        const cruiseSnapshot = await getDocs(cruiseQuery);
        const cruiseData = cruiseSnapshot.docs[0]?.data();
        if (cruiseData) {
          setShipName(cruiseData.ShipName);
        } else {
          console.error("Cruise not found");
        }

        const userProfilesRef = collection(db, "userProfiles");
        const recipientProfileQuery = query(
          userProfilesRef,
          where("uid", "==", contactUserId)
        );
        const recipientProfileSnapshot = await getDocs(recipientProfileQuery);
        const recipientProfileData = recipientProfileSnapshot.docs[0]?.data();
        if (recipientProfileData) {
          setProfileName(recipientProfileData.profileName);
          setRecipientAvatarUrl(recipientProfileData.avatarUrl);
          const recipientInterests = recipientProfileData.interests || [];

          const currentUser = auth.currentUser;
          if (currentUser) {
            const currentUserQuery = query(
              userProfilesRef,
              where("uid", "==", currentUser.uid)
            );
            const currentUserSnapshot = await getDocs(currentUserQuery);
            const currentUserProfile = currentUserSnapshot.docs[0]?.data();
            const currentUserProfileId = currentUserSnapshot.docs[0]?.id;
            const currentUserInterests = currentUserProfile?.interests || [];
            setUserAvatarUrl(currentUserProfile.avatarUrl);

            const shared = recipientInterests.filter((interest) =>
              currentUserInterests.includes(interest)
            );
            setSharedInterests(shared);

            const messagesRef = collection(db, "messages");
            const messagesQuery = query(
              messagesRef,
              where("cruiseId", "==", cruiseId),
              where("receiverId", "in", [contactUserId, currentUser.uid]),
              where("senderId", "in", [contactUserId, currentUser.uid])
            );

            const unsubscribe = onSnapshot(messagesQuery, async (snapshot) => {
              const messagesData = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }));

              const messagesSorted = messagesData.sort(
                (a, b) => a.timestamp?.toDate() - b.timestamp?.toDate()
              );

              setMessages(messagesSorted);

              // Mark messages as seen
              const unseenMessages = messagesSorted.filter(
                (msg) => msg.receiverId === currentUser.uid && !msg.seen
              );

              const batch = writeBatch(db);
              unseenMessages.forEach((msg) => {
                const msgRef = doc(db, "messages", msg.id);
                batch.update(msgRef, { seen: true });
              });

              await batch.commit();
            });

            return () => unsubscribe();
          }
        } else {
          console.error("User profile not found");
        }
      } catch (error) {
        console.error("Error fetching details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDetails();
  }, [cruiseId, contactUserId]);

  const handleSendMessage = async () => {
    const currentUser = auth.currentUser;
    if (!currentUser || !newMessage.trim()) return;

    const messageData = {
      cruiseId,
      senderId: currentUser.uid,
      receiverId: contactUserId,
      text: newMessage,
      timestamp: serverTimestamp(),
      seen: false,
      ...(suggestMeeting && {
        meeting: {
          location: meetingDetails.location,
          date: meetingDetails.date,
          time: meetingDetails.time,
        },
      }),
    };

    try {
      await addDoc(collection(db, "messages"), messageData);
      setNewMessage("");
      setSuggestMeeting(false);
      setMeetingDetails({ location: "", date: "", time: "" });

      await checkAndAwardAchievements(currentUser.uid, contactUserId);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const checkAndAwardAchievements = async (senderId, receiverId) => {
    const firstMateAchievementId = "wFOIdNJn18gJsqCpCh3Q";
    const incomingAchievementId = "bSC6l5pLaQmHZY8ltHw9";

    try {
      const achievementHistoryRef = collection(db, "achievementHistory");

      const [senderHistorySnapshot, receiverHistorySnapshot] =
        await Promise.all([
          getDocs(
            query(
              achievementHistoryRef,
              where("uid", "==", senderId),
              where("achievementId", "==", firstMateAchievementId)
            )
          ),
          getDocs(
            query(
              achievementHistoryRef,
              where("uid", "==", receiverId),
              where("achievementId", "==", incomingAchievementId)
            )
          ),
        ]);

      if (senderHistorySnapshot.empty) {
        await updateUserAchievements(senderId, firstMateAchievementId);
      }

      if (receiverHistorySnapshot.empty) {
        await updateUserAchievements(receiverId, incomingAchievementId);
      }
    } catch (error) {
      console.error("Error checking or awarding achievements:", error);
    }
  };

  const updateUserAchievements = async (userId, achievementId) => {
    const userProfilesRef = collection(db, "userProfiles");
    const userQuery = query(userProfilesRef, where("uid", "==", userId));
    const userSnapshot = await getDocs(userQuery);
    const userDoc = userSnapshot.docs[0];

    if (!userDoc) {
      console.error("User profile not found for awarding achievement");
      return;
    }

    const userDocRef = doc(db, "userProfiles", userDoc.id);
    const achievementHistoryRef = collection(db, "achievementHistory");

    await updateDoc(userDocRef, {
      achievements: arrayUnion(achievementId),
      points: increment(10),
    });

    await addDoc(achievementHistoryRef, {
      achievementId,
      timestamp: serverTimestamp(),
      uid: userId,
    });
  };

  const handleToggleMeeting = () => {
    setSuggestMeeting(!suggestMeeting);
  };

  const handleFullScreenAvatar = (avatarUrl) => {
    const fullScreenOverlay = document.createElement("div");
    fullScreenOverlay.style.position = "fixed";
    fullScreenOverlay.style.top = 0;
    fullScreenOverlay.style.left = 0;
    fullScreenOverlay.style.width = "100%";
    fullScreenOverlay.style.height = "100%";
    fullScreenOverlay.style.backgroundColor = "rgba(0, 0, 0, 0.8)";
    fullScreenOverlay.style.display = "flex";
    fullScreenOverlay.style.alignItems = "center";
    fullScreenOverlay.style.justifyContent = "center";
    fullScreenOverlay.style.zIndex = "9998";

    const fullScreenImg = document.createElement("img");
    fullScreenImg.src = avatarUrl;
    fullScreenImg.style.maxWidth = "90%";
    fullScreenImg.style.maxHeight = "90%";
    fullScreenImg.style.border = "2px solid white";
    fullScreenImg.style.borderRadius = "10px";

    const closeBtn = document.createElement("button");
    closeBtn.innerText = "Close";
    closeBtn.style.position = "fixed";
    closeBtn.style.top = "10px";
    closeBtn.style.right = "10px";
    closeBtn.style.zIndex = "10000";
    closeBtn.style.backgroundColor = "white";
    closeBtn.style.color = "black";
    closeBtn.style.padding = "10px";
    closeBtn.style.border = "none";
    closeBtn.style.borderRadius = "5px";
    closeBtn.style.cursor = "pointer";
    closeBtn.onclick = () => {
      document.body.removeChild(fullScreenOverlay);
    };

    fullScreenOverlay.appendChild(fullScreenImg);
    fullScreenOverlay.appendChild(closeBtn);
    document.body.appendChild(fullScreenOverlay);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto p-4 bg-white rounded-lg shadow-md">
      <Navbar />
      <div className="content">
        <h1 className="text-2xl font-bold mb-4">Chat</h1>
        <div className="mb-4">
          <div className="flex items-center">
            {recipientAvatarUrl ? (
              <img
                src={recipientAvatarUrl}
                alt="Avatar"
                className="w-12 h-12 rounded-full mr-4"
              />
            ) : (
              <FaUserCircle className="w-12 h-12 text-gray-500 mr-4" />
            )}
            <div>
              <h2 className="text-xl font-bold">
                You're chatting with {profileName}
              </h2>
              <p>{shipName}</p>
              <p>
                You both like{" "}
                {sharedInterests.length > 1
                  ? sharedInterests.slice(0, -1).join(", ") +
                    ", and " +
                    sharedInterests.slice(-1)
                  : sharedInterests[0]}
              </p>
            </div>
          </div>
        </div>
        <div className="chat-window mb-4">
          {messages.map((msg) => {
            const isSender = msg.senderId === auth.currentUser.uid;
            return (
              <div
                key={msg.id}
                className={`flex ${
                  isSender ? "justify-end" : "justify-start"
                } mb-2`}
              >
                <div
                  className={`p-2 rounded-lg ${
                    isSender
                      ? "bg-blue-500 text-white"
                      : "bg-gray-300 text-black"
                  }`}
                  style={{ maxWidth: "70%" }}
                >
                  {msg.meeting ? (
                    <div className="text-center bg-orange-200 text-black p-2 rounded-lg">
                      <p className="font-bold">Meeting Suggestion</p>
                      <p>
                        {msg.meeting.location}, {msg.meeting.date},{" "}
                        {msg.meeting.time}
                      </p>
                    </div>
                  ) : (
                    <>
                      <p>{msg.text}</p>
                      <p className="text-xs">
                        {new Date(msg.timestamp?.toDate()).toLocaleString()}
                      </p>
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div className="mb-4">
          <textarea
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded mb-2"
            placeholder="Type your message here..."
          />
          {suggestMeeting && (
            <div className="flex space-x-2 mb-2">
              <input
                type="text"
                value={meetingDetails.location}
                onChange={(e) =>
                  setMeetingDetails({
                    ...meetingDetails,
                    location: e.target.value,
                  })
                }
                className="w-1/4 p-2 border border-gray-300 rounded"
                placeholder="Location"
              />
              <input
                type="date"
                value={meetingDetails.date}
                onChange={(e) =>
                  setMeetingDetails({ ...meetingDetails, date: e.target.value })
                }
                className="w-1/4 p-2 border border-gray-300 rounded"
              />
              <input
                type="time"
                value={meetingDetails.time}
                onChange={(e) =>
                  setMeetingDetails({ ...meetingDetails, time: e.target.value })
                }
                className="w-1/4 p-2 border border-gray-300 rounded"
              />
            </div>
          )}
          <div className="flex items-center space-x-2">
            <button
              onClick={handleSendMessage}
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-200"
            >
              Send
            </button>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={suggestMeeting}
                onChange={handleToggleMeeting}
              />
              <span>Suggest a Meeting</span>
            </label>
          </div>
        </div>
        <div className="flex space-x-4 mb-4">
          <div className="w-1/2 p-2">
            <div className="flex items-center mb-2">
              {userAvatarUrl ? (
                <img
                  src={userAvatarUrl}
                  alt="Avatar"
                  className="w-12 h-12 rounded-full cursor-pointer"
                  onClick={() => handleFullScreenAvatar(userAvatarUrl)}
                />
              ) : (
                <FaUserCircle className="w-12 h-12 text-gray-500" />
              )}
              <div className="ml-2">
                <p className="font-bold">Your Avatar</p>
                <p className="text-xs">
                  Download or full screen your avatar to show your Crews mates
                  to verify your identity.
                </p>
              </div>
            </div>
            <button
              onClick={() => {
                if (userAvatarUrl) {
                  const link = document.createElement("a");
                  link.href = userAvatarUrl;
                  link.download = "avatar.png";
                  link.click();
                }
              }}
              className="text-blue-500 hover:underline"
            >
              Download Avatar
            </button>
          </div>
          <div className="w-1/2 p-2">
            <p className="text-red-500 font-bold mb-2">Safety Reminder:</p>
            <p className="text-sm">
              Remember not to give out any personal information you might not be
              comfortable with, such as real names or room numbers.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
