import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, getDocs, query, where, orderBy } from "firebase/firestore";
import { db } from "../firebase"; // Adjust the import path as necessary
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";

const Chats = () => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [profiles, setProfiles] = useState({});

  useEffect(() => {
    const fetchMessagesAndProfiles = async () => {
      const auth = getAuth();
      onAuthStateChanged(auth, async (currentUser) => {
        if (currentUser) {
          setUser(currentUser);
          try {
            const messagesRef = collection(db, "messages");
            const messagesQuery = query(
              messagesRef,
              orderBy("timestamp", "desc")
            );
            const querySnapshot = await getDocs(messagesQuery);
            const messagesList = querySnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));

            // Fetch user profiles
            const profilesMap = {};
            const fetchProfileName = async (uid) => {
              const userProfileDoc = await getDocs(
                collection(db, "userProfiles")
              );
              userProfileDoc.forEach((doc) => {
                const data = doc.data();
                profilesMap[data.uid] = {
                  profileName: data.profileName,
                  avatarUrl: data.avatarUrl,
                };
              });
            };

            await Promise.all(
              messagesList.map(async (message) => {
                if (message.senderId && !profilesMap[message.senderId]) {
                  await fetchProfileName(message.senderId);
                }
                if (message.receiverId && !profilesMap[message.receiverId]) {
                  await fetchProfileName(message.receiverId);
                }
              })
            );

            setProfiles(profilesMap);
            setMessages(messagesList);
          } catch (error) {
            console.error("Error fetching messages: ", error);
          }
        } else {
          console.log("No user is logged in");
        }
        setLoading(false);
      });
    };

    fetchMessagesAndProfiles();
  }, []);

  const groupMessagesByUser = (messages, currentUserUid) => {
    const groupedMessages = {};
    messages.forEach((message) => {
      const otherUserId =
        message.senderId === currentUserUid
          ? message.receiverId
          : message.senderId;
      if (!groupedMessages[otherUserId]) {
        groupedMessages[otherUserId] = [];
      }
      groupedMessages[otherUserId].push(message);
    });

    return groupedMessages;
  };

  const groupedMessages = groupMessagesByUser(messages, user?.uid || "");

  if (loading) {
    return (
      <div>
        <Navbar />
        <div className="container mx-auto p-4 bg-white rounded-lg shadow-md">
          <h1 className="text-3xl font-bold mb-4">Your Chats</h1>
          <p>Loading...</p>
          <Footer />
        </div>
      </div>
    );
  }

  return (
    <div>
      <Navbar />
      <div className="container mx-auto p-4 bg-white rounded-lg shadow-md">
        <h1 className="text-3xl font-bold mb-4">Your Chats</h1>
        {Object.keys(groupedMessages).length === 0 ? (
          <p>No active chats</p>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            {Object.keys(groupedMessages).map((otherUserId) => {
              const messages = groupedMessages[otherUserId];
              const latestMessage = messages[0];
              const otherUserProfile = profiles[otherUserId] || {
                profileName: "Unknown",
                avatarUrl: "",
              };
              const isCurrentUserSender = latestMessage.senderId === user.uid;

              return (
                <div
                  key={otherUserId}
                  className="border p-4 mb-4 rounded flex flex-col justify-between"
                >
                  <div className="flex items-center mb-2">
                    <img
                      src={otherUserProfile.avatarUrl || "/default-avatar.png"} // Default avatar if none provided
                      alt="Avatar"
                      className="w-10 h-10 rounded-full mr-2 border-2"
                      style={{ borderColor: "rgb(30 64 175)" }}
                    />
                    <p className="font-bold">{otherUserProfile.profileName}</p>
                  </div>
                  <p>
                    <strong>Last message:</strong> {latestMessage.text}
                  </p>
                  <p>
                    <strong>Sent by:</strong>{" "}
                    {isCurrentUserSender ? "You" : "Them"}
                  </p>
                  <p className="text-sm text-gray-500">
                    {latestMessage.timestamp
                      ? new Date(
                          latestMessage.timestamp.toDate()
                        ).toLocaleString()
                      : "No timestamp"}
                  </p>
                  <div className="mt-auto">
                    <Link
                      to={`/contact/${latestMessage.cruiseId}/${otherUserId}`}
                      className="block text-center bg-blue-500 text-white py-1 px-4 rounded hover:bg-blue-700 transition duration-200"
                    >
                      Go to Chat
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <Footer />
      </div>
    </div>
  );
};

export default Chats;
