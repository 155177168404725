// src/components/Footer.js
import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="py-4 mt-8 bg-white">
      <div className="container mx-auto text-center">
        <p>&copy; {new Date().getFullYear()} Crews. All rights reserved.</p>
        <div className="mt-2">
          <Link to="/contact" className="text-blue-500 hover:underline mx-2">
            Contact
          </Link>
          <Link to="/faq" className="text-blue-500 hover:underline mx-2">
            FAQ
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
