import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import { fetchCruisesJson } from "../services/storage"; // Assuming this function fetches cruises.json
import {
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { db } from "../firebase"; // Ensure the correct Firestore import
import axios from "axios"; // Import axios
import Footer from "./Footer"; // Adjust the import if necessary

const UserCruises = () => {
  const navigate = useNavigate(); // Navigation hook for routing
  const [profiles, setProfiles] = useState([]); // State to hold user profile data
  const [cruises, setCruises] = useState([]); // State to hold cruise data
  const [loading, setLoading] = useState(true); // State to manage loading status
  const [authLoading, setAuthLoading] = useState(true); // State to manage auth loading status
  const [showPopup, setShowPopup] = useState(null); // State to manage popup visibility
  const [showConfirm, setShowConfirm] = useState(null); // State to manage confirmation dialog

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchUserProfiles(user.uid); // Fetch user profiles if authenticated
      } else {
        navigate("/login"); // Redirect to login if not authenticated
      }
      setAuthLoading(false); // Set auth loading to false
    });

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, [navigate]);

  const fetchUserProfiles = async (uid) => {
    try {
      const profilesRef = collection(db, "userProfiles"); // Reference to userProfiles collection
      const q = query(profilesRef, where("uid", "==", uid)); // Query to get profiles of the current user
      const querySnapshot = await getDocs(q); // Execute the query

      const userProfiles = await Promise.all(
        querySnapshot.docs.map(async (profileDoc) => {
          const profileData = profileDoc.data();
          const cruise = getCruiseDetails(profileData.cruiseId);
          const imageUrl = cruise
            ? await fetchWikipediaImage(cruise.ShipName)
            : getRandomImageUrl();
          return {
            id: profileDoc.id,
            ...profileData,
            imageUrl,
          };
        })
      );

      setProfiles(userProfiles); // Set the fetched data to state
      fetchCruises(); // Fetch cruises data from JSON
    } catch (error) {
      console.error("Error fetching user profiles: ", error); // Log any errors during data fetching
      setLoading(false); // Set loading to false in case of error
    }
  };

  const fetchCruises = async () => {
    try {
      const cruisesData = await fetchCruisesJson(); // Fetch the cruises JSON data
      setCruises(cruisesData);
      setLoading(false); // Set loading to false as data fetching is done
    } catch (error) {
      console.error("Error fetching cruises: ", error); // Log any errors during data fetching
      setLoading(false); // Set loading to false in case of error
    }
  };

  const getCruiseDetails = (cruiseId) => {
    return cruises.find((cruise) => cruise.CruiseID === cruiseId);
  };

  const calculateDaysLeft = (departureDate) => {
    if (!departureDate) return "N/A"; // Defensive check
    const now = new Date();
    const departure = new Date(departureDate); // Parse the date string
    const diffTime = Math.abs(departure - now);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const formatDate = (date) => {
    if (!date) return "N/A"; // Defensive check
    const parsedDate = new Date(date); // Parse the date string
    return parsedDate.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  };

  const fetchWikipediaImage = async (shipName) => {
    try {
      const response = await axios.get(
        `https://en.wikipedia.org/w/api.php?action=query&titles=${encodeURIComponent(
          shipName
        )}&prop=pageimages&format=json&pithumbsize=1000&origin=*`
      );
      const pages = response.data.query.pages;
      const page = Object.values(pages)[0];
      return page.thumbnail ? page.thumbnail.source : null;
    } catch (error) {
      console.error("Error fetching Wikipedia image:", error);
      return getRandomImageUrl();
    }
  };

  const getRandomImageUrl = () => {
    return `/defaultShip.jpg`;
  };

  const handleDelete = async (profileId) => {
    try {
      await deleteDoc(doc(db, "userProfiles", profileId));
      setProfiles(profiles.filter((profile) => profile.id !== profileId));
      setShowConfirm(null);
    } catch (error) {
      console.error("Error deleting profile: ", error);
    }
  };

  const handleOutsideClick = (e) => {
    if (showPopup && !e.target.closest(".popup")) {
      setShowPopup(null);
    }
  };

  useEffect(() => {
    if (showPopup) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [showPopup]);

  if (authLoading || loading) {
    return <div>Loading...</div>; // Show loading indicator while fetching data
  }

  return (
    <div className="container mx-auto p-4 bg-white rounded-lg shadow-md">
      <h2 className="text-3xl font-bold mb-4">My Cruises</h2>
      {profiles.length === 0 ? (
        <p>You have not joined any cruises.</p> // Show message if no profiles are found
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {profiles.map((profile) => {
            const cruise = getCruiseDetails(profile.cruiseId);
            const daysLeft = cruise
              ? calculateDaysLeft(cruise.DepartureDate)
              : "N/A";
            return (
              <div
                key={profile.id}
                className="bg-gray-100 rounded-lg shadow-md overflow-hidden relative p-4 flex flex-col justify-between"
              >
                <div className="relative h-48 overflow-hidden">
                  <div
                    className="ken-burns-effect bg-cover bg-center h-full w-full"
                    style={{
                      backgroundImage: `url(${profile.imageUrl})`,
                    }}
                  ></div>
                  <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-50"></div>
                  <div className="absolute top-2 left-2 overflow-hidden">
                    {profile.avatarUrl && (
                      <img
                        src={profile.avatarUrl}
                        alt="Avatar"
                        className="rounded-full border-4 border-white object-cover"
                        style={{ width: "75px", height: "75px" }} // Increased size of avatar
                      />
                    )}
                  </div>
                  <div className="absolute bottom-2 left-2 text-white">
                    <h3 className="text-xl font-bold">
                      {cruise ? cruise.ShipName : "Unknown Ship"}
                    </h3>
                  </div>
                  <div className="absolute top-2 right-2">
                    <div className="bg-blue-500 text-white py-1 px-3 rounded-lg shadow-lg font-bold">
                      <p className="text-lg">{daysLeft} days</p>
                    </div>
                  </div>
                  <div className="absolute bottom-2 right-2 z-50">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowPopup(profile.id);
                      }}
                      className="text-white"
                    >
                      &#x22EE; {/* Three dots icon */}
                    </button>
                    {showPopup === profile.id && (
                      <div className="absolute right-0 bottom-full mb-2 bg-white border border-gray-300 rounded-lg shadow-lg p-2 z-50 animate-popup popup">
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowConfirm(profile.id);
                          }}
                          className="block text-left w-full px-4 py-2 text-red-600 hover:bg-red-100 font-bold whitespace-nowrap"
                        >
                          Abandon Ship
                        </button>
                      </div>
                    )}
                  </div>
                  {showConfirm === profile.id && (
                    <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-90 z-50 animate-popup">
                      <div className="bg-white p-4 rounded-lg shadow-lg z-50 w-3/4 md:w-1/2">
                        <p className="text-center font-bold mb-4">
                          Are you sure?
                        </p>
                        <div className="mt-4 flex justify-center space-x-4">
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowConfirm(null);
                            }}
                            className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-700 transition duration-200"
                          >
                            Cancel
                          </button>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDelete(profile.id);
                            }}
                            className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-700 transition duration-200"
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="p-4 bg-gray-100 mt-auto">
                  <p>
                    <strong>
                      {" "}
                      {cruise ? cruise.CruiseName : "Unknown Cruise"}
                    </strong>
                  </p>
                  <p>
                    <strong>Departure Date:</strong>{" "}
                    {cruise ? formatDate(cruise.DepartureDate) : "N/A"}
                  </p>
                  <p>
                    <strong>Traveller Type:</strong> {profile.travellerType}
                  </p>
                  <div className="text-center">
                    <button
                      className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-200"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/cruise-details/${profile.cruiseId}`);
                      }}
                    >
                      View Cruise Details
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
      <Footer />
    </div>
  );
};

export default UserCruises;
