import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db, auth } from "../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  deleteDoc,
} from "firebase/firestore";
import axios from "axios";
import { FaUserCircle, FaEllipsisV } from "react-icons/fa";
import Footer from "./Footer";

const CruiseDetails = () => {
  const { cruiseId } = useParams();
  const navigate = useNavigate();
  const [cruiseDetails, setCruiseDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [currentUserProfile, setCurrentUserProfile] = useState(null);
  const [shipImage, setShipImage] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [beacons, setBeacons] = useState([]);

  useEffect(() => {
    const fetchCruiseDetails = async () => {
      try {
        const cruisesRef = collection(db, "cruises");
        const cruiseQuery = query(
          cruisesRef,
          where("CruiseID", "==", cruiseId)
        );
        const cruiseSnapshot = await getDocs(cruiseQuery);
        const cruiseData = cruiseSnapshot.docs[0]?.data();

        const userProfilesRef = collection(db, "userProfiles");
        const userQuery = query(
          userProfilesRef,
          where("cruiseId", "==", cruiseId)
        );
        const userSnapshot = await getDocs(userQuery);
        const userProfileData = userSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setCruiseDetails(cruiseData);
        setUsers(userProfileData);
        setLoading(false);

        const currentUser = auth.currentUser;
        if (currentUser) {
          const currentUserQuery = query(
            userProfilesRef,
            where("cruiseId", "==", cruiseId),
            where("uid", "==", currentUser.uid)
          );
          const currentUserSnapshot = await getDocs(currentUserQuery);
          const currentUserProfileData = currentUserSnapshot.docs[0]?.data();
          setCurrentUserProfile(currentUserProfileData);
        }

        if (cruiseData?.ShipName) {
          const imageUrl = await fetchWikipediaImage(cruiseData.ShipName);
          setShipImage(imageUrl);
        }

        const beaconsRef = collection(db, "beacons");
        const beaconsQuery = query(
          beaconsRef,
          where("cruiseId", "==", cruiseId)
        );
        const beaconsSnapshot = await getDocs(beaconsQuery);
        const beaconsData = await Promise.all(
          beaconsSnapshot.docs.map(async (doc) => {
            const beaconData = doc.data();
            const submitterProfileQuery = query(
              userProfilesRef,
              where("uid", "==", beaconData.userId)
            );
            const submitterProfileSnapshot = await getDocs(
              submitterProfileQuery
            );
            const submitterProfile = submitterProfileSnapshot.docs[0]?.data();
            return {
              id: doc.id,
              submitterName: submitterProfile?.profileName,
              ...beaconData,
            };
          })
        );

        setBeacons(beaconsData);
      } catch (error) {
        console.error("Error fetching cruise details: ", error);
        setLoading(false);
      }
    };

    fetchCruiseDetails();
  }, [cruiseId]);

  const fetchWikipediaImage = async (shipName) => {
    try {
      const response = await axios.get(
        `https://en.wikipedia.org/w/api.php?action=query&titles=${encodeURIComponent(
          shipName
        )}&prop=pageimages&format=json&pithumbsize=1000&origin=*`
      );
      const pages = response.data.query.pages;
      const page = Object.values(pages)[0];
      return page.thumbnail ? page.thumbnail.source : null;
    } catch (error) {
      console.error("Error fetching Wikipedia image:", error);
      return null;
    }
  };

  const formatTravellerType = (type) => {
    switch (type) {
      case "Solo":
        return "Solo traveler";
      case "Couple":
        return "Couple";
      case "Family":
        return "Family";
      default:
        return type;
    }
  };

  const formatOpenToMeeting = (types, travellerType) => {
    const formattedTypes = types.map((type) => {
      let formattedType;
      switch (type) {
        case "Solo":
          formattedType = "solo travelers";
          break;
        case "Couple":
          formattedType = "couples";
          break;
        case "Family":
          formattedType = "families";
          break;
        default:
          formattedType = type;
      }
      return type === travellerType ? `other ${formattedType}` : formattedType;
    });

    if (formattedTypes.length > 2) {
      return (
        formattedTypes.slice(0, -1).join(", ") +
        ", and " +
        formattedTypes.slice(-1)
      );
    }
    return formattedTypes.join(" and ");
  };

  const getSortedInterests = (userInterests, currentUserInterests) => {
    const commonInterests = userInterests.filter((interest) =>
      currentUserInterests.includes(interest)
    );
    const otherInterests = userInterests.filter(
      (interest) => !currentUserInterests.includes(interest)
    );
    return [...commonInterests, ...otherInterests.sort()];
  };

  const calculateMatchPercentage = (userInterests, currentUserInterests) => {
    const commonInterests = userInterests.filter((interest) =>
      currentUserInterests.includes(interest)
    ).length;
    return Math.round((commonInterests / currentUserInterests.length) * 100);
  };

  const getMatchColor = (percentage) => {
    if (percentage >= 80) {
      return "bg-gradient-to-r from-yellow-400 to-yellow-600";
    } else if (percentage >= 60) {
      return "bg-gradient-to-r from-green-400 to-green-600";
    } else {
      return "bg-gradient-to-r from-blue-400 to-yellow-300";
    }
  };

  const getProgressColor = (percentage) => {
    if (percentage <= 9) {
      return "#ADD8E6";
    } else if (percentage <= 19) {
      return "#BDD9DC";
    } else if (percentage <= 29) {
      return "#CDDBD2";
    } else if (percentage <= 39) {
      return "#DDDDC8";
    } else if (percentage <= 49) {
      return "#EDDEBE";
    } else if (percentage <= 59) {
      return "#F0DFB4";
    } else if (percentage <= 69) {
      return "#F2E0AA";
    } else if (percentage <= 79) {
      return "#F4E1A0";
    } else if (percentage <= 89) {
      return "#F6E296";
    } else {
      return "#FFDF7F";
    }
  };

  const getTextColor = (percentage) => {
    return "text-black";
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const cancelBeacon = async (beaconId) => {
    try {
      await deleteDoc(doc(db, "beacons", beaconId));
      setBeacons(beacons.filter((beacon) => beacon.id !== beaconId));
    } catch (error) {
      console.error("Error cancelling beacon: ", error);
    }
  };

  console.log("users.length:", users.length);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto p-4 bg-white rounded-lg shadow-md">
      <div className="flex flex-col md:flex-row pt-4 md:space-x-4 relative">
        <div className="md:w-1/2 mb-4 md:mb-0 relative">
          {shipImage && (
            <div
              className="h-64 w-full bg-cover bg-center rounded-lg relative"
              style={{ backgroundImage: `url(${shipImage})` }}
            >
              <div className="absolute inset-0 bg-gradient-to-t from-blue-900 via-blue-800 to-transparent opacity-75"></div>
              <div className="absolute bottom-4 left-4 text-white z-10">
                <h2 className="text-3xl font-bold mb-2">
                  {cruiseDetails?.ShipName}
                </h2>
                <p className="text-lg">
                  {new Date(cruiseDetails?.DepartureDate).toLocaleDateString(
                    "en-US",
                    {
                      month: "long",
                      day: "numeric",
                      year: "numeric",
                    }
                  )}{" "}
                  - {cruiseDetails?.DeparturePort}
                </p>
              </div>
              <div className="absolute top-2 right-2 text-white z-10">
                <button onClick={toggleMenu} className="text-white">
                  <FaEllipsisV />
                </button>
                {menuOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-2 z-20">
                    <button
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-200 w-full text-left"
                      onClick={() => navigate(`/beacon/${cruiseId}`)}
                    >
                      Light a Beacon
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
          {!shipImage && (
            <div className="h-64 w-full bg-gray-300 rounded-lg flex items-center justify-center">
              <p>No image available for this ship.</p>
            </div>
          )}
        </div>
        <div className="md:w-1/2 bg-gray-100 p-4 rounded-lg shadow-md flex flex-col justify-end">
          <div className="flex justify-between items-baseline">
            <h3 className="text-2xl font-bold mb-4">My Profile</h3>
            {currentUserProfile && (
              <button
                onClick={() =>
                  navigate(`/profile-edit/${currentUserProfile.uid}`)
                }
                className="text-sm text-blue-500 hover:underline"
              >
                Edit Profile
              </button>
            )}
          </div>
          {currentUserProfile ? (
            <div className="flex flex-col md:flex-row">
              <div className="flex flex-col items-center md:w-1/2 mb-4 md:mb-0">
                <div className="w-32 h-32 mb-4">
                  <img
                    src={currentUserProfile.avatarUrl}
                    alt="Avatar"
                    className="rounded-full w-full h-full object-cover"
                  />
                </div>
                <p className="text-xl font-bold">
                  {currentUserProfile.profileName}
                </p>
              </div>
              <div className="flex flex-col md:w-1/2">
                <p>
                  {formatTravellerType(currentUserProfile.travellerType)}{" "}
                  looking to meet{" "}
                  {formatOpenToMeeting(
                    currentUserProfile.openToMeeting,
                    currentUserProfile.travellerType
                  )}
                </p>
                <p>
                  <strong>Bio:</strong> {currentUserProfile.bio}
                </p>
                <p>
                  <strong>Interests:</strong>
                </p>
                <div>
                  {currentUserProfile.interests.map((interest, index) => (
                    <span
                      key={index}
                      className="bg-gradient-to-b from-blue-500 to-purple-500 text-white rounded-lg px-2 py-1 mr-2 mb-2 inline-block"
                    >
                      {interest}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <p>No profile found for this cruise.</p>
          )}
        </div>
      </div>

      {beacons.length > 0 && (
        <div className="mt-8">
          <h3 className="text-2xl font-bold mb-4">Beacons</h3>
          {beacons.map((beacon) => (
            <div
              key={beacon.id}
              className="bg-red-100 p-4 rounded-lg shadow-md mb-4"
            >
              <p>
                <strong>{beacon.submitterName}</strong> is in need of{" "}
                <strong>{beacon.category}</strong>
              </p>
              {beacon.additionalInfo && (
                <p className="text-sm text-gray-600">{beacon.additionalInfo}</p>
              )}
              <button
                className="mt-2 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-200"
                onClick={() =>
                  navigate(`/contact/${cruiseId}/${beacon.userId}`)
                }
              >
                Can you help them out?
              </button>
              {auth.currentUser?.uid === beacon.userId && (
                <button
                  className="mt-2 bg-red-500 text-white py-2 px-4 rounded hover:bg-red-700 transition duration-200 ml-2"
                  onClick={() => cancelBeacon(beacon.id)}
                >
                  Cancel Beacon
                </button>
              )}
            </div>
          ))}
        </div>
      )}

      <h3 className="text-2xl font-bold mt-8 mb-4">
        Other Users on This Cruise
      </h3>
      {users.length === 1 ? (
        <p>
          Looks like you're the first on board! Check back regularly to find
          your Crews mates!
        </p>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {users
            .filter((user) => user.uid !== auth.currentUser?.uid)
            .map((user) => {
              const sortedInterests = getSortedInterests(
                user.interests,
                currentUserProfile?.interests || []
              );
              const matchPercentage = calculateMatchPercentage(
                user.interests,
                currentUserProfile?.interests || []
              );

              return (
                <div
                  key={user.id}
                  className="bg-white p-4 rounded-lg shadow-md border border-gray-300"
                >
                  <div className="flex items-center mb-4">
                    <div className="relative">
                      {user.avatarUrl ? (
                        <div className="relative">
                          <img
                            src={user.avatarUrl}
                            alt="Avatar"
                            className="w-16 h-16 rounded-full object-cover mr-4 border-gray-300"
                          />
                          <svg
                            className="absolute top-0 left-0 w-16 h-16 rounded-full"
                            viewBox="0 0 36 36"
                          >
                            <path
                              className="text-gray-300"
                              d="M18 2.0845
                              a 15.9155 15.9155 0 0 1 0 31.831
                              a 15.9155 15.9155 0 0 1 0 -31.831"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                            />
                            <path
                              className="text-gray-400"
                              d="M18 2.0845
                              a 15.9155 15.9155 0 0 1 0 31.831"
                              fill="none"
                              stroke={getProgressColor(matchPercentage)}
                              strokeWidth="2"
                              strokeDasharray={`100, 100`}
                            />
                          </svg>
                        </div>
                      ) : (
                        <div className="relative">
                          <FaUserCircle className="w-16 h-16 text-gray-500 mr-4 border-gray-300" />
                          <svg
                            className="absolute top-0 left-0 w-16 h-16 rounded-full"
                            viewBox="0 0 36 36"
                          >
                            <path
                              className="text-gray-300"
                              d="M18 2.0845
                              a 15.9155 15.9155 0 0 1 0 31.831
                              a 15.9155 15.9155 0 0 1 0 -31.831"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                            />
                            <path
                              className="text-gray-400"
                              d="M18 2.0845
                              a 15.9155 15.9155 0 0 1 0 31.831"
                              fill="none"
                              stroke={getProgressColor(matchPercentage)}
                              strokeWidth="2"
                              strokeDasharray={`100, 100`}
                            />
                          </svg>
                        </div>
                      )}
                    </div>
                    <div>
                      <p className="text-lg font-bold">{user.profileName}</p>
                      <p
                        className={`rounded-full px-2 py-1 ${getTextColor(
                          matchPercentage
                        )} ${getMatchColor(matchPercentage)} font-bold`}
                        style={{
                          background: getProgressColor(matchPercentage),
                        }}
                      >
                        {matchPercentage}%
                      </p>
                      <p>{user.travellerType}</p>
                      <button
                        onClick={() =>
                          navigate(`/contact/${cruiseId}/${user.uid}`)
                        }
                        className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-200"
                      >
                        Contact
                      </button>
                    </div>
                  </div>
                  <p>
                    <strong>Bio:</strong> {user.bio}
                  </p>
                  <p>
                    <strong>Interests:</strong>
                  </p>
                  <div>
                    {sortedInterests.map((interest, index) => (
                      <span
                        key={index}
                        className={`${
                          currentUserProfile?.interests.includes(interest)
                            ? "bg-gradient-to-b from-green-300 to-green-500 font-bold"
                            : "bg-gradient-to-b from-gray-300 to-gray-500"
                        } text-white rounded-lg px-2 py-1 mr-2 mb-2 inline-block`}
                      >
                        {interest}
                      </span>
                    ))}
                  </div>
                </div>
              );
            })}
        </div>
      )}
      <Footer />
    </div>
  );
};

export default CruiseDetails;
