import React, { useEffect, useState } from "react";
import { auth, db } from "../firebase";
import { useNavigate } from "react-router-dom";
import { fetchCruisesJson } from "../services/storage";
import { collection, query, where, getDocs } from "firebase/firestore";
import axios from "axios";
import Footer from "./Footer";
import placeholderImage from "./fallback.png";

const ITEMS_PER_PAGE = 18;

const Dashboard = () => {
  const navigate = useNavigate();
  const [cruises, setCruises] = useState([]);
  const [filteredCruises, setFilteredCruises] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [userProfiles, setUserProfiles] = useState({});
  const [images, setImages] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setUser(user);
        try {
          const data = await fetchCruisesJson();
          setCruises(data);
          setFilteredCruises(
            data.sort(
              (a, b) => new Date(a.DepartureDate) - new Date(b.DepartureDate)
            )
          );
          setLoading(false);
        } catch (error) {
          setError(error);
          setLoading(false);
        }
      } else {
        navigate("/login");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    filterCruises();
  }, [searchTerm, startDate, endDate, cruises]);

  useEffect(() => {
    const fetchUserProfiles = async () => {
      if (user) {
        const profilesRef = collection(db, "userProfiles");
        const q = query(profilesRef, where("uid", "==", user.uid));
        const querySnapshot = await getDocs(q);
        const profiles = querySnapshot.docs.reduce((acc, doc) => {
          acc[doc.data().cruiseId] = true;
          return acc;
        }, {});
        setUserProfiles(profiles);
      }
    };

    fetchUserProfiles();
  }, [user]);

  const filterCruises = () => {
    let filtered = cruises;

    if (searchTerm) {
      filtered = filtered.filter(
        (cruise) =>
          cruise.CruiseName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          cruise.ShipName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          cruise.DeparturePort.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (startDate) {
      filtered = filtered.filter(
        (cruise) => new Date(cruise.DepartureDate) >= new Date(startDate)
      );
    }

    if (endDate) {
      filtered = filtered.filter(
        (cruise) => new Date(cruise.DepartureDate) <= new Date(endDate)
      );
    }

    setFilteredCruises(filtered);
    setCurrentPage(1);
  };

  const resetFilters = () => {
    setSearchTerm("");
    setStartDate("");
    setEndDate("");
    setFilteredCruises(cruises);
  };

  const fetchWikipediaImage = async (shipName) => {
    try {
      const response = await axios.get(
        `https://en.wikipedia.org/w/api.php?action=query&titles=${encodeURIComponent(
          shipName
        )}&prop=pageimages&format=json&pithumbsize=500&origin=*`
      );
      const pages = response.data.query.pages;
      const page = Object.values(pages)[0];
      return page.thumbnail ? page.thumbnail.source : null;
    } catch (error) {
      console.error("Error fetching Wikipedia image:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchImages = async () => {
      const imagePromises = cruises.map(async (cruise) => {
        const image = await fetchWikipediaImage(cruise.ShipName);
        return { [cruise.ShipName]: image };
      });
      const imageResults = await Promise.all(imagePromises);
      const imageMap = imageResults.reduce(
        (acc, curr) => ({ ...acc, ...curr }),
        {}
      );
      setImages(imageMap);
    };

    if (cruises.length > 0) {
      fetchImages();
    }
  }, [cruises]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  };

  const totalPages = Math.ceil(filteredCruises.length / ITEMS_PER_PAGE);
  const paginatedCruises = filteredCruises.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="container mx-auto p-4 bg-gray-100 rounded-lg shadow-md">
      <h1 className="text-3xl font-bold mb-4">Find an Upcoming Cruise</h1>
      <p className="mb-4">
        Find your ship and sailing date, and then create your Crews profile for
        that cruise.
      </p>
      <div className="mb-4 p-4 border border-gray-300 rounded bg-white shadow-sm">
        <h2 className="text-xl font-bold mb-2">Filter Cruises</h2>
        <div className="flex flex-col space-y-2 md:flex-row md:space-y-0 md:space-x-2">
          <div className="flex flex-col">
            <label className="text-sm text-gray-700 mb-1">
              Search by Name, Ship, or Port
            </label>
            <input
              type="text"
              placeholder="Search..."
              className="p-2 border border-gray-300 rounded"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-sm text-gray-700 mb-1">Start Date</label>
            <input
              type="date"
              className="p-2 border border-gray-300 rounded"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-sm text-gray-700 mb-1">End Date</label>
            <input
              type="date"
              className="p-2 border border-gray-300 rounded"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <button
            onClick={resetFilters}
            className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-700 transition duration-200 self-end md:self-auto"
          >
            Reset Filters
          </button>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
        {paginatedCruises.map((cruise) => {
          const isOnBoard = userProfiles[cruise.CruiseID];
          const imageUrl = images[cruise.ShipName] || placeholderImage;

          return (
            <div
              key={cruise.CruiseID}
              className={`bg-white rounded-lg shadow-md overflow-hidden flex flex-col h-full ${
                isOnBoard ? "border-l-4 border-green-500" : ""
              }`}
            >
              {imageUrl && (
                <div
                  className="h-48 w-full bg-cover bg-center"
                  style={{ backgroundImage: `url(${imageUrl})` }}
                ></div>
              )}
              <div
                className={`p-4 flex-grow ${
                  isOnBoard ? "bg-green-500 text-white" : ""
                }`}
              >
                <p className="text-xl font-bold">{cruise.CruiseName}</p>
                <p className="text-md font-semibold uppercase">
                  {cruise.ShipName}
                </p>
                <p className="font-semibold">
                  {formatDate(cruise.DepartureDate)} - {cruise.DeparturePort}
                </p>
              </div>
              <div
                className={`p-4 bg-gray-100 ${isOnBoard ? "bg-green-500" : ""}`}
              >
                <button
                  onClick={() => {
                    if (isOnBoard) {
                      navigate(`/cruise-details/${cruise.CruiseID}`);
                    } else {
                      navigate(`/profile/${cruise.CruiseID}`);
                    }
                  }}
                  className={`w-full py-2 px-4 rounded transition duration-200 ${
                    isOnBoard
                      ? "bg-green-700 text-white hover:bg-green-900"
                      : "bg-blue-500 text-white hover:bg-blue-700"
                  }`}
                >
                  {isOnBoard ? "I'm on board!" : "Join Your Crews!"}
                </button>
              </div>
            </div>
          );
        })}
      </div>
      <div className="mt-4 flex justify-center">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            className={`mx-1 px-3 py-1 rounded ${
              currentPage === index + 1
                ? "bg-blue-500 text-white"
                : "bg-gray-300 text-gray-700 hover:bg-gray-400"
            }`}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </button>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default Dashboard;
