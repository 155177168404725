import React, { useState } from "react";
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  query,
  where,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import Navbar from "./Navbar";
import Footer from "./Footer";

const CruiseUploader = () => {
  const [jsonInput, setJsonInput] = useState("");
  const [message, setMessage] = useState("");

  const handleJsonInputChange = (e) => {
    setJsonInput(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const db = getFirestore();
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      setMessage("No user is logged in");
      return;
    }

    try {
      const cruises = JSON.parse(jsonInput);

      if (!Array.isArray(cruises)) {
        throw new Error("JSON data is not an array");
      }

      const cruisesCollection = collection(db, "cruises");
      const existingCruiseIDs = [];

      // Fetch existing cruises to avoid duplicates
      const querySnapshot = await getDocs(cruisesCollection);
      querySnapshot.forEach((doc) => {
        existingCruiseIDs.push(doc.data().CruiseID);
      });

      let addedCount = 0;

      for (const cruise of cruises) {
        if (!existingCruiseIDs.includes(cruise.CruiseID)) {
          await addDoc(cruisesCollection, cruise);
          addedCount++;
        }
      }

      setMessage(`Successfully added ${addedCount} cruises`);
    } catch (error) {
      setMessage(`Error parsing JSON or adding cruises: ${error.message}`);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="container mx-auto p-4 bg-white rounded-lg shadow-md">
        <h1 className="text-3xl font-bold mb-4">Upload Cruises</h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="jsonInput"
            >
              Paste JSON here:
            </label>
            <textarea
              id="jsonInput"
              value={jsonInput}
              onChange={handleJsonInputChange}
              className="w-full p-2 border border-gray-300 rounded"
              rows="10"
            ></textarea>
          </div>
          <button
            type="submit"
            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-200"
          >
            Submit
          </button>
        </form>
        {message && <div className="mt-4 text-red-500">{message}</div>}
        <Footer />
      </div>
    </div>
  );
};

export default CruiseUploader;
