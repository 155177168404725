// src/components/AssignAchievement.js
import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import {
  collection,
  getDocs,
  updateDoc,
  doc,
  addDoc,
  serverTimestamp,
  getDoc,
} from "firebase/firestore";

const AssignAchievement = () => {
  const [users, setUsers] = useState([]);
  const [achievements, setAchievements] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedAchievement, setSelectedAchievement] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const usersSnapshot = await getDocs(collection(db, "users"));
        const achievementsSnapshot = await getDocs(
          collection(db, "achievements")
        );

        setUsers(
          usersSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );
        setAchievements(
          achievementsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
        );
      } catch (error) {
        console.error("Error fetching users or achievements: ", error);
      }
    };

    fetchData();
  }, []);

  const handleAssign = async () => {
    if (!selectedUser || !selectedAchievement) {
      setError("Please select a user and an achievement.");
      return;
    }

    try {
      const userRef = doc(db, "users", selectedUser);
      const userDoc = await getDoc(userRef);
      const userData = userDoc.data();

      const achievementRef = doc(db, "achievements", selectedAchievement);
      const achievementDoc = await getDoc(achievementRef);
      const achievementData = achievementDoc.data();

      if (userData && achievementData) {
        const updatedPoints = userData.points + achievementData.points;
        const updatedAchievements = [
          ...userData.achievements,
          selectedAchievement,
        ];

        await updateDoc(userRef, {
          points: updatedPoints,
          achievements: updatedAchievements,
        });

        await addDoc(collection(db, "achievementHistory"), {
          achievementId: selectedAchievement,
          timestamp: serverTimestamp(),
          uid: selectedUser,
        });

        setError("");
        alert("Achievement assigned successfully!");
      } else {
        setError("User or achievement not found.");
      }
    } catch (error) {
      console.error("Error assigning achievement: ", error);
      setError("Error assigning achievement.");
    }
  };

  return (
    <div className="container mx-auto p-4 bg-white rounded-lg shadow-md">
      <h2 className="text-3xl font-bold mb-4">Assign Achievement</h2>
      {error && <p className="text-red-500">{error}</p>}
      <div className="space-y-4">
        <div>
          <label className="block text-gray-700">Select User</label>
          <select
            value={selectedUser}
            onChange={(e) => setSelectedUser(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
          >
            <option value="">Select a user</option>
            {users.map((user) => (
              <option key={user.id} value={user.id}>
                {user.email}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className="block text-gray-700">Select Achievement</label>
          <select
            value={selectedAchievement}
            onChange={(e) => setSelectedAchievement(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
          >
            <option value="">Select an achievement</option>
            {achievements.map((achievement) => (
              <option key={achievement.id} value={achievement.id}>
                {achievement.name}
              </option>
            ))}
          </select>
        </div>
        <button
          onClick={handleAssign}
          className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-200"
        >
          Assign Achievement
        </button>
      </div>
    </div>
  );
};

export default AssignAchievement;
