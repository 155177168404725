import React, { useEffect, useState, useRef, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db, auth } from "../firebase";
import { collection, getDocs, addDoc } from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadString,
  getDownloadURL,
} from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import AvatarCreator from "./AvatarCreator";
import SoloIcon from "./icons/solo.svg";
import CoupleIcon from "./icons/couple.svg";
import FamilyIcon from "./icons/family.svg";
import { FaCheck } from "react-icons/fa";
import Footer from "./Footer";

const ProfileForm = () => {
  const { cruiseId } = useParams();
  const navigate = useNavigate();
  const [profileName, setProfileName] = useState("");
  const [bio, setBio] = useState("");
  const [travellerType, setTravellerType] = useState("");
  const [openToMeeting, setOpenToMeeting] = useState([]);
  const [interests, setInterests] = useState([]);
  const [availableActivities, setAvailableActivities] = useState([]);
  const [charCount, setCharCount] = useState(0);
  const [avatarUrl, setAvatarUrl] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const avatarRef = useRef();

  const [avatarDetails, setavatarDetails] = useState({
    gradients: [],
    selectedGradient: null,
    selectedDuck: null,
    selectedGlasses: null,
    selectedHat: null,
  });

  useEffect(() => {
    const fetchActivities = async () => {
      const activitiesCollection = collection(db, "activities");
      const activitiesSnapshot = await getDocs(activitiesCollection);
      const activitiesList = activitiesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAvailableActivities(activitiesList);
    };

    fetchActivities();
  }, []);

  const handleToggleInterest = (activity) => {
    if (interests.includes(activity)) {
      setInterests(interests.filter((i) => i !== activity));
    } else {
      setInterests([...interests, activity]);
    }
  };

  const handleToggleOpenToMeeting = (type) => {
    if (openToMeeting.includes(type)) {
      setOpenToMeeting(openToMeeting.filter((t) => t !== type));
    } else {
      setOpenToMeeting([...openToMeeting, type]);
    }
  };

  const handleBioChange = (e) => {
    setBio(e.target.value);
    setCharCount(e.target.value.length);
  };

  const handleUpdate = useCallback((newDetails) => {
    setavatarDetails(newDetails);
  }, []);

  const combineSvgs = async () => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = 250;
    canvas.height = 300;

    // Check if gradients are defined and selectedGradient exists
    if (avatarDetails.gradients && avatarDetails.selectedGradient) {
      const gradient = ctx.createLinearGradient(0, 0, 0, canvas.height);
      const selectedGradient = avatarDetails.selectedGradient;

      if (selectedGradient === "White-Grey") {
        gradient.addColorStop(0, "white");
        gradient.addColorStop(1, "grey");
      } else if (selectedGradient === "Dark Grey") {
        gradient.addColorStop(0, "#4b4b4b");
        gradient.addColorStop(1, "#bdbdbd");
      } else if (selectedGradient === "Gentle Pink") {
        gradient.addColorStop(0, "#ff9a9e");
        gradient.addColorStop(1, "#fad0c4");
      } else if (selectedGradient === "Gentle Purple") {
        gradient.addColorStop(0, "#a18cd1");
        gradient.addColorStop(1, "#fbc2eb");
      } else if (selectedGradient === "Gentle Peach") {
        gradient.addColorStop(0, "#fad0c4");
        gradient.addColorStop(1, "#ffd1ff");
      } else if (selectedGradient === "Gentle Blue") {
        gradient.addColorStop(0, "#a1c4fd");
        gradient.addColorStop(1, "#c2e9fb");
      } else if (selectedGradient === "Gentle Green") {
        gradient.addColorStop(0, "#d4fc79");
        gradient.addColorStop(1, "#96e6a1");
      } else if (selectedGradient === "Gentle Orange") {
        gradient.addColorStop(0, "#ffecd2");
        gradient.addColorStop(1, "#fcb69f");
      }

      ctx.fillStyle = gradient;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    }

    const drawImage = (src, x = 0, y = 0) =>
      new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "Anonymous";
        img.onload = () => {
          ctx.drawImage(img, x, y, canvas.width, canvas.height);
          resolve();
        };
        img.onerror = (error) => {
          console.error(`Error loading image ${src}: `, error);
          reject(error);
        };
        img.src = src;
      });

    try {
      if (avatarDetails.selectedDuck)
        await drawImage(avatarDetails.selectedDuck);
      if (avatarDetails.selectedGlasses)
        await drawImage(avatarDetails.selectedGlasses);
      if (avatarDetails.selectedHat) await drawImage(avatarDetails.selectedHat);
    } catch (error) {
      console.error("Error combining SVGs: ", error);
      return null;
    }

    return canvas.toDataURL("image/png");
  };

  const handleAvatarCreate = async () => {
    const storage = getStorage();
    const combinedImage = await combineSvgs();
    if (!combinedImage) {
      throw new Error("Failed to combine SVGs");
    }
    const avatarRefStorage = ref(storage, `avatars/${uuidv4()}.png`);
    await uploadString(avatarRefStorage, combinedImage.split(",")[1], "base64");
    const avatarUrl = await getDownloadURL(avatarRefStorage);
    setAvatarUrl(avatarUrl);
    return avatarUrl;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);

    const user = auth.currentUser;
    if (!user) {
      console.error("User is not authenticated");
      return;
    }

    try {
      const avatarUrl = await handleAvatarCreate();

      const profileData = {
        email: user.email,
        uid: user.uid,
        cruiseId,
        profileName,
        bio,
        travellerType,
        openToMeeting,
        interests,
        avatarUrl,
      };

      await addDoc(collection(db, "userProfiles"), profileData);
      navigate("/my-cruises");
    } catch (error) {
      console.error("Error saving profile: ", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const getButtonColor = (index) => {
    const colors = [
      "bg-red-500 text-white",
      "bg-green-500 text-white",
      "bg-blue-500 text-white",
      "bg-yellow-500 text-black",
      "bg-purple-500 text-white",
      "bg-pink-500 text-white",
      "bg-teal-500 text-white",
    ];
    return colors[index % colors.length];
  };

  return (
    <div className="container mx-auto p-4 bg-white rounded-lg shadow-md">
      <h2 className="text-3xl font-bold mb-4">Create Your Profile</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <h2 className="text-2xl font-bold mb-2">Profile Name and Bio</h2>
          <input
            type="text"
            value={profileName}
            onChange={(e) => setProfileName(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div>
          <label className="block text-gray-700">
            Tell us about you{" "}
            <span className="text-sm text-gray-500">
              (remember to keep yourself safe)
            </span>
          </label>
          <input
            type="text"
            value={bio}
            onChange={handleBioChange}
            maxLength="100"
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
          <div
            className={`text-sm ${
              charCount === 100 ? "text-red-500" : "text-gray-500"
            } text-right`}
          >
            {charCount}/100
          </div>
        </div>
        <div>
          <h2 className="text-2xl font-bold mb-2">
            Type of traveler: (select one)
          </h2>
          <div className="flex justify-center space-x-4">
            {[
              { type: "Solo", icon: SoloIcon },
              { type: "Couple", icon: CoupleIcon },
              { type: "Family", icon: FamilyIcon },
            ].map(({ type, icon: Icon }) => (
              <button
                key={type}
                type="button"
                className={`py-4 px-8 rounded-lg text-xl flex flex-col items-center ${
                  travellerType === type
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 text-gray-700"
                }`}
                onClick={() => setTravellerType(type)}
              >
                <img
                  src={Icon}
                  alt={`${type} icon`}
                  className="h-12 w-12 mb-2"
                />
                {type}
              </button>
            ))}
          </div>
        </div>
        <div>
          <h2 className="text-2xl font-bold mb-2">
            Open to meeting: (select at least one)
          </h2>
          <div className="flex justify-center space-x-4">
            {[
              { type: "Solo", icon: SoloIcon },
              { type: "Couple", icon: CoupleIcon },
              { type: "Family", icon: FamilyIcon },
            ].map(({ type, icon: Icon }) => (
              <button
                key={type}
                type="button"
                className={`py-4 px-8 rounded-lg text-xl flex flex-col items-center ${
                  openToMeeting.includes(type)
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 text-gray-700"
                }`}
                onClick={() => handleToggleOpenToMeeting(type)}
              >
                <img
                  src={Icon}
                  alt={`${type} icon`}
                  className="h-12 w-12 mb-2"
                />
                {type}
              </button>
            ))}
          </div>
        </div>
        <div>
          <h2 className="text-2xl font-bold mb-2">Interests</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
            {availableActivities.map((activity, index) => (
              <button
                key={activity.id}
                type="button"
                className={`py-1 px-3 rounded-full ${getButtonColor(index)} ${
                  interests.includes(activity.name)
                    ? "opacity-100"
                    : "opacity-70"
                }`}
                onClick={() => handleToggleInterest(activity.name)}
              >
                <div className="flex items-center">
                  {activity.name}
                  {interests.includes(activity.name) && (
                    <FaCheck className="ml-2 text-white" />
                  )}
                </div>
              </button>
            ))}
          </div>
        </div>
        <AvatarCreator onChange={handleUpdate} />
        <div className="flex space-x-4">
          <button
            type="submit"
            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-200"
            disabled={isSubmitting}
          >
            Submit
          </button>
          <button
            type="button"
            onClick={() => navigate("/dashboard")}
            className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-700 transition duration-200"
          >
            Cancel
          </button>
        </div>
      </form>
      <Footer />
    </div>
  );
};

export default ProfileForm;
