import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db, auth } from "../firebase";
import { collection, addDoc } from "firebase/firestore";
import Navbar from "./Navbar";
import Footer from "./Footer";

const Beacon = () => {
  const { cruiseId } = useParams(); // Changed from shipId to cruiseId
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [category, setCategory] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false); // State for success message

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        navigate("/login");
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!cruiseId) {
      setError("Cruise ID is missing.");
      return;
    }

    if (category === "Other" && !additionalInfo) {
      setError(
        "Please provide additional information for the selected category."
      );
      return;
    }

    try {
      await addDoc(collection(db, "beacons"), {
        cruiseId,
        userId: user.uid,
        category,
        additionalInfo,
        timestamp: new Date(),
      });
      setSuccess(true);
      setTimeout(() => {
        navigate(`/cruise-details/${cruiseId}`);
      }, 3000); // Redirect after 3 seconds
    } catch (error) {
      setError(`Error lighting beacon: ${error.message}`);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="container mx-auto p-4 bg-white rounded-lg shadow-md">
        <h2 className="text-3xl font-bold mb-4">Light a Beacon</h2>
        <p>
          We've all been there, you've run out of something essential and the
          stores are closed! Light a beacon by filling out this form and we'll
          put an alert up on Crews for one of your Crews mates to save the day!
        </p>
        <p className="text-sm">
          If someone reaches out, remember our safety tips. We always encourage
          meet ups to happen in public, open parts of the ship, and be careful
          about sharing information like real names and room numbers.
        </p>
        {error && <div className="text-red-500 mb-4">{error}</div>}
        {success && (
          <div className="text-green-500 mb-4">
            Beacon successfully lit! Redirecting...
          </div>
        )}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-gray-700">Category</label>
            <select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
              required
            >
              <option value="" disabled>
                Select a category
              </option>
              <option value="Adhesive Bandages">Adhesive Bandages</option>
              <option value="Batteries">Batteries</option>
              <option value="Charger Cable">Charger Cable</option>
              <option value="Diapers">Diapers</option>
              <option value="Pacifier">Pacifier</option>
              <option value="Period Products">Period Products</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div>
            <label className="block text-gray-700">
              Additional information (product size, type, etc.)
            </label>
            <input
              type="text"
              value={additionalInfo}
              onChange={(e) => setAdditionalInfo(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
              placeholder={category === "Other" ? "Required" : "Optional"}
              required={category === "Other"}
            />
            <p className="text-sm text-red-500">
              Do not use this form to request or offer medication. For medical
              emergencies, contact the ship's staff immediately.
            </p>
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-700 transition duration-200"
          >
            Submit
          </button>
        </form>
        <Footer />
      </div>
    </div>
  );
};

export default Beacon;
