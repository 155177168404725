// src/components/Locker.js
import React, { useEffect, useState } from "react";
import { db, auth } from "../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import tinycolor from "tinycolor2";
import Footer from "./Footer";

const Locker = () => {
  const [achievements, setAchievements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [gradients, setGradients] = useState({});

  useEffect(() => {
    const fetchAchievements = async (uid) => {
      try {
        const userRef = doc(db, "users", uid);
        const userSnapshot = await getDoc(userRef);
        const userData = userSnapshot.data();

        const achievementHistoryRef = collection(db, "achievementHistory");
        const achievementHistoryQuery = query(
          achievementHistoryRef,
          where("uid", "==", uid)
        );
        const achievementHistorySnapshot = await getDocs(
          achievementHistoryQuery
        );

        const achievementsData = await Promise.all(
          achievementHistorySnapshot.docs.map(async (docSnapshot) => {
            const achievementRef = doc(
              db,
              "achievements",
              docSnapshot.data().achievementId
            );
            const achievementSnapshot = await getDoc(achievementRef);
            const achievementData = achievementSnapshot.data();
            achievementData.timestamp = docSnapshot.data().timestamp?.toDate();
            achievementData.id = docSnapshot.data().achievementId;
            return achievementData;
          })
        );

        setAchievements(achievementsData);
        setUser(userData);
        setLoading(false);

        const gradientsData = await generateGradients(achievementsData);
        setGradients(gradientsData);
      } catch (error) {
        console.error("Error fetching achievements: ", error);
        setLoading(false);
      }
    };

    const checkAuthState = () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        fetchAchievements(currentUser.uid);
      } else {
        auth.onAuthStateChanged((user) => {
          if (user) {
            fetchAchievements(user.uid);
          } else {
            setLoading(false);
          }
        });
      }
    };

    checkAuthState();
  }, []);

  const generateGradients = async (achievementsData) => {
    const gradientsData = {};
    for (const achievement of achievementsData) {
      const gradient = await getGradient(achievement.imageUrl);
      gradientsData[achievement.id] = gradient;
    }
    return gradientsData;
  };

  const formatPoints = (points) => {
    return points.toLocaleString();
  };

  const getGradient = (imageUrl) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.src = imageUrl;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        const data = ctx.getImageData(0, 0, img.width, img.height).data;

        const colors = {};
        for (let i = 0; i < data.length; i += 4) {
          const rgb = `${data[i]},${data[i + 1]},${data[i + 2]}`;
          colors[rgb] = (colors[rgb] || 0) + 1;
        }

        const dominantColor = Object.keys(colors).reduce((a, b) =>
          colors[a] > colors[b] ? a : b
        );
        const [r, g, b] = dominantColor.split(",").map(Number);
        const color = tinycolor({ r, g, b });
        const complement = color.complement().toHexString();

        resolve(
          `linear-gradient(to bottom, ${color.toHexString()}, ${complement})`
        );
      };
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto p-4 bg-white rounded-lg shadow-md">
      {user && (
        <div className="mb-4">
          <h2 className="text-3xl font-bold mb-2">My Locker</h2>
          <p className="text-xl">Email: {user.email}</p>
          <p className="text-xl">
            Points:{" "}
            <span className="font-bold text-dark-blue">
              {formatPoints(user.points)}
            </span>
          </p>
        </div>
      )}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {achievements.map((achievement) => (
          <div
            key={achievement.id}
            className="bg-gray-100 p-4 rounded-lg shadow-md"
          >
            <div className="relative">
              <div
                className="w-full h-32 bg-cover bg-center rounded-t-lg"
                style={{
                  background: gradients[achievement.id] || "transparent",
                }}
              ></div>
              <img
                src={achievement.imageUrl}
                alt={achievement.name}
                className="absolute inset-0 w-full h-full object-contain p-4 hover:animate-wobble"
              />
            </div>
            <div className="p-4 bg-light-grey rounded-b-lg">
              <h3 className="text-xl font-bold mb-2">{achievement.name}</h3>
              <p className="mb-2">{achievement.description}</p>
              <p className="font-bold text-dark-blue mb-2">
                Points: {formatPoints(achievement.points)}
              </p>
              <p className="text-sm text-gray-500">
                Earned: {achievement.timestamp?.toLocaleDateString()}
              </p>
            </div>
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default Locker;
