// src/index.js
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./tailwind.css"; // Ensure this includes your background styles
import App from "./App";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
