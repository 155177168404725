// src/components/Home.js
import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Footer from "./Footer"; // Adjust the import if necessary

const Home = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <Helmet>
        <title>Crews - Find Your Cruise Companions</title>
      </Helmet>
      <div className="container mx-auto p-8">
        <div className="flex flex-col md:flex-row items-center justify-between mb-8 bg-transparent">
          <div className="w-full md:w-1/2 flex justify-center md:justify-start">
            <img
              src="/splashlogo.svg"
              alt="Logo"
              className="w-full max-w-xs sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2xl mb-4 md:mb-0"
            />
          </div>
          <div className="w-full md:w-1/2 bg-white p-6 rounded-lg shadow-md text-center md:text-left">
            <h1 className="text-3xl font-bold text-gray-800 mb-4">
              Find Your Cruise Companions
            </h1>
            <p className="text-lg text-gray-600 mb-6">
              Join the community of cruise lovers and make friends at sea!
            </p>
            <div className="flex justify-center md:justify-start space-x-4">
              <Link
                to="/login"
                className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-200"
              >
                Log In
              </Link>
              <Link
                to="/register"
                className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-700 transition duration-200"
              >
                Register
              </Link>
            </div>
          </div>
        </div>
        <div className="bg-white py-12">
          <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">
            How It Works
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="text-center">
              <h3 className="text-xl font-semibold text-gray-700 mb-2">
                1. Select your ship
              </h3>
              <p className="text-gray-600">
                Make sure you've got the right date and port!
              </p>
            </div>
            <div className="text-center">
              <h3 className="text-xl font-semibold text-gray-700 mb-2">
                2. Customize your profile
              </h3>
              <p className="text-gray-600">
                How are you traveling? Who would you like to meet? What are your
                interests?
              </p>
            </div>
            <div className="text-center">
              <h3 className="text-xl font-semibold text-gray-700 mb-2">
                3. Meet your Crews!
              </h3>
              <p className="text-gray-600">
                See other passengers also looking to make life-long friends.
              </p>
            </div>
          </div>
        </div>
        {/* Testimonials Section (Commented out for now) */}
        {/*
        <div className="bg-white py-12">
          <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">
            Testimonials
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="bg-gray-100 p-6 rounded-lg shadow-md">
              <p className="text-gray-700 mb-4">
                "Crews made my cruise so much more enjoyable. I met some amazing
                people and had a blast!" - Jane Doe
              </p>
            </div>
            <div className="bg-gray-100 p-6 rounded-lg shadow-md">
              <p className="text-gray-700 mb-4">
                "Thanks to Crews, I found a group of friends to share my
                adventures with. Highly recommended!" - John Smith
              </p>
            </div>
          </div>
        </div>
        */}
        <Footer />
      </div>
    </div>
  );
};

export default Home;
