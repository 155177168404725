import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, db } from "../firebase";
import { FaUserCircle } from "react-icons/fa";
import { collection, query, where, getDocs } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Navbar = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [user, setUser] = useState(null);
  const [hasChats, setHasChats] = useState(false);
  const [unseenMessageCount, setUnseenMessageCount] = useState(0);
  const [hasCruiseProfile, setHasCruiseProfile] = useState(false);
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setUser(user);
        console.log("Logged in user ID: ", user.uid);

        // Check if the user has any chats
        const chatsRef = collection(db, "messages");
        const chatsQuery = query(chatsRef, where("senderId", "==", user.uid));

        const chatsSnapshot = await getDocs(chatsQuery);
        const fetchedMessages = chatsSnapshot.docs.map((doc) => doc.data());

        console.log("Fetched messages: ", fetchedMessages);

        if (!chatsSnapshot.empty) {
          setHasChats(true);
          let unseenCount = 0;
          chatsSnapshot.forEach((doc) => {
            const data = doc.data();
            if (!data.seen && data.receiverId === user.uid) {
              unseenCount++;
            }
          });
          setUnseenMessageCount(unseenCount);
        } else {
          setHasChats(false);
        }

        // Check if the user has a profile for a cruise
        const profilesRef = collection(db, "userProfiles");
        const profilesQuery = query(profilesRef, where("uid", "==", user.uid));

        const profilesSnapshot = await getDocs(profilesQuery);
        setHasCruiseProfile(!profilesSnapshot.empty);
      } else {
        setUser(null);
        setHasChats(false);
        setUnseenMessageCount(0);
        setHasCruiseProfile(false);
      }
    });
    return () => unsubscribe();
  }, []);

  const handleLogout = () => {
    auth
      .signOut()
      .then(() => {
        navigate("/login");
      })
      .catch((error) => {
        console.error("Error signing out: ", error);
      });
  };

  const handleNavigation = (path) => {
    setShowDropdown(false);
    navigate(path);
  };

  const notifyNoChats = () => {
    toast.info("You need to find a Crews mate to chat with first!", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <nav className="fixed top-0 left-0 w-full bg-blue-800 z-50">
      <ToastContainer />
      <div className="container flex justify-between items-center p-4">
        <div className="flex items-center">
          <Link to={user ? "/dashboard" : "/"}>
            <img
              src="/crews_logo.svg"
              alt="Crews Logo"
              className="h-8 w-auto"
            />
          </Link>
          {user && (
            <>
              <Link
                to="/dashboard"
                className="ml-4 px-4 py-2 bg-blue-700 text-white text-lg rounded hover:bg-blue-600 transition duration-200"
              >
                Find an Upcoming Cruise
              </Link>
              {hasCruiseProfile && (
                <Link
                  to="/my-cruises"
                  className="ml-4 px-4 py-2 bg-blue-700 text-white text-lg rounded hover:bg-blue-600 transition duration-200"
                >
                  My Cruises
                </Link>
              )}
            </>
          )}
        </div>
        <div className="space-x-4">
          {user ? (
            <div className="relative">
              <button
                onClick={toggleDropdown}
                className="flex items-center space-x-2 focus:outline-none text-white"
              >
                <FaUserCircle size={32} />
              </button>
              {showDropdown && (
                <div className="absolute right-0 mt-2 w-56 bg-white border border-gray-200 rounded-lg shadow-lg z-50">
                  <div className="px-4 py-2 text-gray-800 border-b border-gray-200 overflow-hidden overflow-ellipsis whitespace-nowrap">
                    {user.email}
                  </div>
                  <button
                    onClick={() =>
                      hasChats ? handleNavigation("/my-chats") : notifyNoChats()
                    }
                    className={`block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100 ${
                      !hasChats ? "cursor-not-allowed" : ""
                    }`}
                    disabled={!hasChats}
                  >
                    My Chats{" "}
                    {unseenMessageCount > 0 && (
                      <span className="ml-2 inline-block px-2 py-1 text-xs font-semibold text-white bg-red-500 rounded-full">
                        {unseenMessageCount}
                      </span>
                    )}
                  </button>
                  <button
                    onClick={() => handleNavigation("/my-cruises")}
                    className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    My Cruises
                  </button>
                  <button
                    onClick={() => handleNavigation("/locker")}
                    className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    My Locker
                  </button>
                  <hr />
                  <button
                    onClick={() => handleNavigation("/change-password")}
                    className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    Change Password
                  </button>
                  <button
                    onClick={handleLogout}
                    className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    Logout
                  </button>
                </div>
              )}
            </div>
          ) : (
            <>
              <Link to="/login" className="text-white">
                Login
              </Link>
              <Link to="/register" className="text-white">
                Sign Up
              </Link>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
